import React, { useEffect, useRef, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import MainLayout from '../layouts/MainLayout';
import Navigate from '../components/Navigate/Navigate'
import { TabNav, TabContent } from '../components/Tabs';
import Event from '../components/Event/Event';
// import ParticleAnimate from '../components/ParticleAnimate/ParticleAnimate';
import SocialPanel from '../components/SocialPanel/SocialPanel';
import GuidePanel from '../components/Guide/GuidePanel';
import Poster from '../img/IMG_9199.jpg'
import Sednev from '../img/sednev.png'
import Signature from '../img/signature.png'
// import TopVideo from '../video/cube.mp4'
import Paint from '../img/paint.png'
import Slogan1 from '../img/slogan1.png'
import Slogan2 from '../img/slogan2.png'
import Erath from '../img/erath.png'
import Man from '../img/man.png'
import Cub1 from '../img/cub1.png'
import Cub2 from '../img/cub2.png'
import Cub3 from '../img/cub3.png'
import Cub4 from '../img/cub4.png'
import Cub5 from '../img/cub5.png'
import Cub6 from '../img/cub6.png'
import Cub7 from '../img/cub7.png'
import Cub8 from '../img/cub8.png'
import Cub9 from '../img/cub9.png'
import Light4 from '../img/light4.png'
import Light1 from '../img/light1.png'
import Light2 from '../img/light2.png'
import Light3 from '../img/light3.png'
import Rector from '../img/rector.jpg'
import Warrior from '../img/kv-warrior.png'
import book1 from '../img/notebook1.png'
import book2 from '../img/notebook2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import Video from '../video/output.mp4'
import Video2 from '../video/video.mp4'


const calendarEvents = [
	{
		id: 1,
		data_title: '1 Сентября',
		events: [
			// {time:'8.30', title: '', content: 'Встречи в инженерных школах с первокурсниками (по отдельному расписанию)'},
			{time:'', title: '', content: 'Встреча первокурсников с администрацией школ по расписанию: <a href="https://rasp.tpu.ru/" target="_blank">https://rasp.tpu.ru/</a>'},
			// {time:'9.00 – 12.00', title: '', content: 'Вайб у Главного корпуса: музыка, фотозона, сладкие сюрпризы'},
			// {time:'12:00', title: '', content: '<b>Обзорная экскурсия по историческому залу Музея истории ТПУ. (Главный корпус, ауд. 315).</b><p>Экскурсия об истории создания и открытия ТТИ, первых отделениях, профессорах и выпускниках; о деятельности вуза в разные периоды времени.</p>'},
			{time:'12:00', title: '', content: 'Обзорная экскурсия по историческому залу Музея истории ТПУ (пр. Ленина, 30, 3 этаж), запись обязательна.'},
			// {time:'12:00', title: '', content: '<b>«Тайны Главного» – экскурсия по Главному корпусу ТПУ. (Главный корпус). </b><p>Экскурсия по Главному корпусу ТПУ: история строительства корпуса, о первом названии корпуса, о первых аудиториях с заходом в Актовый зал, а также рассказ о «деталях» и символах Томского политеха.</p>'},
			// {time:'15:00', title: '', content: '<b>Обзорная экскурсия по историческому залу Музея истории ТПУ. (Главный корпус, ауд. 315).</b><p>Экскурсия об истории создания и открытия ТТИ, первых отделениях, профессорах и выпускниках; о деятельности вуза в разные периоды времени.</p>'},
			// {time:'15:00', title: '', content: '<b>«Тайны Главного» – экскурсия по Главному корпусу ТПУ. (Главный корпус). </b><p>Экскурсия по Главному корпусу ТПУ: история строительства корпуса, о первом названии корпуса, о первых аудиториях с заходом в Актовый зал, а также рассказ о «деталях» и символах Томского политеха.</p>'},
			// {time:'15:00', title: '', content: '<b>Пешеходная экскурсия «Европейский квартал».</b><br/>(Главный корпус – Инженерный корпус – Физический корпус – Химический корпус –  Горный корпус). <p>Экскурсия об истории строительства исторического кампуса вуза, часть зданий которого стали памятниками архитектуры федерального и регионального значения.</p>'},
			// {time:'18.00', title: '', content: 'Специальное собрание клуба живого общения «ТОК»: встреча и.о. ректора ТПУ Дмитрия Седнева с первокурсниками в Инженерном дворике (вход в ворота справа от Главного корпуса)'},
			// {time:'19:00', title: '', content: '<b>Пешеходная экскурсия «Европейский квартал».</b><br/>(Главный корпус – Инженерный корпус – Физический корпус – Химический корпус –  Горный корпус). <p>Экскурсия об истории строительства исторического кампуса вуза, часть зданий которого стали памятниками архитектуры федерального и регионального значения.</p>'},
			// {time:'19.00', title: '', content: 'Музыкальный квартирник в Инженерном дворике'},
			{time:'15:00/18:30', title: '', content: 'Пешеходная экскурсия «Европейский квартал», запись обязательна.'},
			{time:'18:00', title: '', content: 'Вечеринка в Инженерном дворике (пр. Ленина, 30, справа от Главного корпуса).'},
			{time:'18:30', title: '', content: 'Экскурсия по Главному корпусу ТПУ «Тайны главного» (пр. Ленина, 30), запись обязательна.'},
		]
	},
	// {
	// 	id: 2,
	// 	data_title: '2 Сентября',
	// 	events: [
	// 		{time:'12:00', title: '', content: '<b>Обзорная экскурсия по историческому залу Музея истории ТПУ. (Главный корпус, ауд. 315).</b><p>Экскурсия об истории создания и открытия ТТИ, первых отделениях, профессорах и выпускниках; о деятельности вуза в разные периоды времени.</p>'},
	// 		{time:'12:00', title: '', content: '<b>«Тайны Главного» – экскурсия по Главному корпусу ТПУ. (Главный корпус). </b><p>Экскурсия по Главному корпусу ТПУ: история строительства корпуса, о первом названии корпуса, о первых аудиториях с заходом в Актовый зал, а также рассказ о «деталях» и символах Томского политеха.</p>'},
	// 		{time:'15:00', title: '', content: '<b>Обзорная экскурсия по историческому залу Музея истории ТПУ. (Главный корпус, ауд. 315).</b><p>Экскурсия об истории создания и открытия ТТИ, первых отделениях, профессорах и выпускниках; о деятельности вуза в разные периоды времени.</p>'},
	// 		{time:'15:00', title: '', content: '<b>«Тайны Главного» – экскурсия по Главному корпусу ТПУ. (Главный корпус). </b><p>Экскурсия по Главному корпусу ТПУ: история строительства корпуса, о первом названии корпуса, о первых аудиториях с заходом в Актовый зал, а также рассказ о «деталях» и символах Томского политеха.</p>'},
	// 		{time:'15:00', title: '', content: '<b>Пешеходная экскурсия «Европейский квартал».</b><br/>(Главный корпус – Инженерный корпус – Физический корпус – Химический корпус –  Горный корпус). <p>Экскурсия об истории строительства исторического кампуса вуза, часть зданий которого стали памятниками архитектуры федерального и регионального значения.</p>'},
	// 		{time:'19:00', title: '', content: '<b>Пешеходная экскурсия «Европейский квартал».</b><br/>(Главный корпус – Инженерный корпус – Физический корпус – Химический корпус –  Горный корпус). <p>Экскурсия об истории строительства исторического кампуса вуза, часть зданий которого стали памятниками архитектуры федерального и регионального значения.</p>'},
	// 	]
	// },
	{
		id: 3,
		data_title: '3 Сентября',
		events: [
			// {time:'12:00', title: '', content: '<b>Обзорная экскурсия по историческому залу Музея истории ТПУ. (Главный корпус, ауд. 315).</b><p>Экскурсия об истории создания и открытия ТТИ, первых отделениях, профессорах и выпускниках; о деятельности вуза в разные периоды времени.</p>'},
			// {time:'12:00', title: '', content: '<b>«Тайны Главного» – экскурсия по Главному корпусу ТПУ. (Главный корпус). </b><p>Экскурсия по Главному корпусу ТПУ: история строительства корпуса, о первом названии корпуса, о первых аудиториях с заходом в Актовый зал, а также рассказ о «деталях» и символах Томского политеха.</p>'},
			// {time:'15:00', title: '', content: '<b>Обзорная экскурсия по историческому залу Музея истории ТПУ. (Главный корпус, ауд. 315).</b><p>Экскурсия об истории создания и открытия ТТИ, первых отделениях, профессорах и выпускниках; о деятельности вуза в разные периоды времени.</p>'},
			// {time:'15:00', title: '', content: '<b>«Тайны Главного» – экскурсия по Главному корпусу ТПУ. (Главный корпус). </b><p>Экскурсия по Главному корпусу ТПУ: история строительства корпуса, о первом названии корпуса, о первых аудиториях с заходом в Актовый зал, а также рассказ о «деталях» и символах Томского политеха.</p>'},
			{time:'13:00', title: '', content: 'Шествие первокурсников (стадион «Политехник», ул. 19-й Гвардейской Дивизии, 20, ст.1).'},
			{time:'13:30', title: '', content: 'Спартакиада первокурсников, презентация студенческих объединений, творческий концерт (стадион «Политехник», ул. 19-й Гвардейской Дивизии, 20, ст.1).'},
		]
	},
	// {
	// 	id: 4,
	// 	data_title: '4 Сентября',
	// 	events: [
	// 		{time:'13.00', title: '', content: 'Шествие студентов инженерных и исследовательских школ ТПУ (ул. Вершинина (построение на участке от ул. Учебной до ул. Полины Осипенко) – проезд Вершинина – ул. Лыткина – ул. 19-й Гвардейской Дивизии – стадион «Политехник»)'},
	// 		{time:'14.30', title: '', content: 'Парад участников соревнований, посвященных началу нового учебного года. Старт спортивных испытаний'},
	// 		{time:'15.00', title: '', content: 'Работа интерактивных развлекательных площадок, презентация студенческих объединений ТПУ'},
	// 		{time:'16.30', title: '', content: 'Концертно-развлекательная программа «Политех, полетели!». Розыгрыш ноутбука среди первокурсников'},
	// 	]
	// },
]

export const linksArr = [
	{name:'Первокурсникам', href:'/#welcome'},
	// {name:'Календарь', href:'/#calendar'},
	// {name:'Розыгрыш', href:'/#event'},
	{name:'Справочник', href:'/guide'},
	// {name:'Мерч ТПУ', href:'https://shop.tpu.ru/'},
]

export default function MainPage(){

	useEffect(() => {
		Aos.init();
	},[])
	const [videoPlaying, setVideoPlaying] = useState(false)
	const vidRef = useRef(null);
	const vidBtnRef = useRef(null);

	const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

	const handlePlayVideo = () => {
		if(!videoPlaying) {
			vidRef.current.play();
			vidRef.current.controls = true
			vidRef.current.style.objectFit = ''
			vidBtnRef.current.style.display = 'none'
		}
		setVideoPlaying(!videoPlaying)
	}


	return(
		<MainLayout>
			<div className="top-bg">
				<div className='position-absolute'></div>
				<Navigate linksArr={linksArr}/>
				<div className="container top-content">
					<div className="top-container">
						<div className="top-description">
							<div className="top-politech">ПОЛИТЕХ</div>
							<div className="top-supernovae">СВЕРХНОВЫЕ</div>
						</div>
						<div className="top-cubs">
							<img src={Cub1} alt="Cub1" className="cub-1" loading="lazy"/>
							<img src={Cub2} alt="Cub2" className="cub-2" loading="lazy"/>
							<img src={Cub2} alt="Cub3" className="cub-3" loading="lazy"/>
							<img src={Cub4} alt="Cub4" className="cub-4" loading="lazy"/>
							<img src={Cub5} alt="Cub5" className="cub-5" loading="lazy"/>
							<img src={Cub6} alt="Cub6" className="cub-6" loading="lazy"/>
							<img src={Cub7} alt="Cub7" className="cub-7" loading="lazy"/>
							<img src={Cub8} alt="Cub8" className="cub-8" loading="lazy"/>
							<img src={Cub9} alt="Cub9" className="cub-9" loading="lazy"/>
							<img src={Light1} alt="Light-1" className="light-1" loading="lazy"/>
							<img src={Light2} alt="Light-2" className="light-2" loading="lazy"/>
							<img src={Light3} alt="Light-2" className="light-3" loading="lazy"/>
						</div>
					</div>
				</div>
				{/*<div className="container">*/}
				{/*	<div className='row'>*/}
				{/*		<div className='col-lg-6 mt-5' style={{zIndex:10}}>*/}
				{/*			<div className='main_top_title mt-xl-5 pt-xl-5' data-aos="zoom-out" data-aos-duration="800" style={{lineHeight:0}}>*/}
				{/*				/!* <span>Миру нужны</span><br/>*/}
				{/*				<span className='b_gc'>инженеры</span> *!/*/}
				{/*				<img src={Slogan1} className="img-fluid" alt="Slogan1"/><br/>*/}
				{/*				<img src={Slogan2} className="img-fluid" alt="Slogan1"/>*/}
				{/*			</div>*/}
				{/*			<div data-aos="zoom-out" data-aos-duration="2000" className='mt-4 mt-xl-5'>Думай. Учись. Действуй</div>*/}
				{/*		</div>*/}
				{/*		/!* <div className='col-lg-6 mt-xl-5'>*/}
				{/*			<video data-aos="zoom-in" className='mt-xl-5' data-aos-duration="600" width="100%" height={450} autoPlay loop muted>*/}
				{/*				<source src={`${TopVideo}`} type="video/mp4"/>*/}
				{/*			</video>*/}
				{/*		</div> *!/*/}
				{/*	</div>*/}
				{/*	<div className='man position-absolute'><img src={Man} alt="Man"/></div>*/}
				{/*</div>*/}
				{/*<div className='erath position-absolute'><img src={Erath} alt="Erath"/></div>*/}
				{/*<div className='sky_bot position-absolute'></div>*/}
			</div>
			<div style={{position: 'relative'}} id="welcome">

				<div className="rektor_block pb-4">
					<div className="back_gc1 text-white py-5">
						<div className="container">
							<h2 className='title_block'>Добро пожаловать в <span className='text-black' data-aos="fade-up" data-aos-duration="1000">Томский политех,</span> дорогие первокурсники!</h2>
						</div>
					</div>
					{/*<div className="container pt-5">*/}
					{/*	<div className="row pt-4" data-aos="fade-up">*/}
					{/*		<div className="col-md-4 mt-3">*/}
					{/*			<img src={Rector} className="img-fluid" alt="Rector"/>*/}
					{/*		</div>*/}
					{/*		<div className="col-md-7 mt-3">*/}
					{/*			<div>*/}
					{/*				<p><b>Леонид Сухих</b>, и.о. ректора Томского политеха</p>*/}
					{/*				<p>*/}
					{/*					Дорогой первокурсник! Будущий инженер, исследователь, предприниматель! Поздравляю тебя с поступлением в Томский политехнический университет!*/}
					{/*				</p>*/}
					{/*				<p>*/}
					{/*					Ты много работал, долго готовился к экзаменам, ответственно подошел к выбору университета. Ты большой молодец! Я с радостью приветствую тебя в большой и дружной семье томских политехников!*/}
					{/*				</p>*/}
					{/*				<p>*/}
					{/*					Спасибо тебе за активность, упорство, целеустремленность и любознательность. Эти качества будут помогать тебе в дальнейшем – в учебе и жизни. Сохрани и приумножь их.*/}
					{/*				</p>*/}
					{/*				<p>*/}
					{/*					Впереди у тебя насыщенные и интересные годы жизни в стенах университета. Это этап, на котором ты заведешь друзей и товарищей на всю жизнь, узнаешь много нового, научишься сложному и станешь инженером.*/}
					{/*				</p>*/}
					{/*				<p>*/}
					{/*					Здесь перед тобой откроется множество возможностей: большая наука, творчество, спорт, волонтерство, насыщенная общественная жизнь. Ты поймешь, что инженер – это не просто профессия, это образ мышления и стиль жизни. Поймешь, что нет нерешаемых задач и недостижимых целей. Почувствуешь радость, разработав и создав свой первый прибор, собрав установку или даже сделав проект завода. Ты увидишь, как сложнейшие конструкции создаются в реальном мире. Мечты становятся явью.*/}
					{/*				</p>*/}
					{/*				<p>*/}
					{/*					И через время мы встретимся с тобой на выпускном. Уверен, что ты получишь свое кольцо «Миссия: инженер» и будешь с достоинством нести гордое звание инженера.*/}
					{/*				</p>*/}
					{/*				<p>*/}
					{/*					Удачи тебе! Все только начинается!*/}
					{/*				</p>*/}
					{/*			</div>*/}
					{/*			/!* <div className='d-flex align-items-center gap-4 mt-5' data-aos="fade-up" data-aos-duration="2000">*/}
					{/*				<div><img src={Sednev} alt="rector TPU"/></div>*/}
					{/*				<div className='mt-5'>*/}
					{/*					<p className='text-end pt-2'>*/}
					{/*						<img width={150} src={Signature} alt="Signature"/>*/}
					{/*					</p>*/}
					{/*				</div>*/}
					{/*			</div> *!/*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>

			</div>

			<div className="index_video my-5" data-aos="fade-up" data-aos-duration="1000">
				<div ref={vidBtnRef}><FontAwesomeIcon icon={faPlayCircle} className="video_btn" onClick={handlePlayVideo}/></div>
				<video ref={vidRef} width='100%' style={{maxHeight:'500px', objectFit:'cover'}} poster={Poster}>
					<source src={Video2}/>
				</video>
			</div>

			{/*<div className="calendar my-5 py-lg-5" id="calendar">*/}
			{/*	<div className="timeline">*/}
			{/*		<div className="container">*/}
			{/*			<div className="row align-items-center" data-aos="zoom-in">*/}
			{/*				<div className="col-md-3">*/}
			{/*					<h2 className="navbar-brand">Календарь</h2>*/}
			{/*				</div>*/}
			{/*				<div className="col-md-9">*/}
			{/*					<ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">*/}
			{/*					{calendarEvents.map((tab, i) => (*/}
			{/*						<TabNav key={i} id={`ftab${tab.id}`} dataBsTarget={`tab${tab.id}`} active={tab.id === 1 && true}>{tab.data_title}</TabNav>*/}
			{/*					))}*/}
			{/*					</ul>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div className="container my-4 pb-5">*/}
			{/*		<div className="tab-content" id="myTabContent">*/}
			{/*			{calendarEvents.map(tab => (*/}
			{/*				<TabContent key={tab.id} id={`tab${tab.id}`} classes={["tab-pane", "fade", "show", tab.id === 1 && "active"]}>*/}
			{/*					{tab.events.map((event, indx) => (*/}
			{/*						<Event key={indx}>*/}
			{/*							<div data-aos="fade-right" className="col-md-2 col-4 text-center event-time">*/}
			{/*								{event.time}*/}
			{/*							</div>*/}
			{/*							<div data-aos="fade-left" className="col-md-10 col-8 event-content">*/}
			{/*								{event.title && <h5 className="fw-bold">{event.title}</h5>}*/}
			{/*								{renderHTML(event.content)}*/}
			{/*							</div>*/}
			{/*						</Event>*/}
			{/*					))}*/}
			{/*				</TabContent>*/}
			{/*			))}*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div className='container mt-4 mb-5'>*/}
			{/*		<div className='guide-box guide_beauty_box_2'>*/}
			{/*			<h2>Внимание!</h2>*/}
			{/*			Запись на экскурсии по телефону 8 (3822) 70-56-71 или через группу ВКонтакте <a href='https://vk.com/kmtpu' className='text-white' target='_blank'>https://vk.com/kmtpu</a>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<div className="challenge_block" id="event">*/}
			{/*	<div className="container challenge_desc">*/}
			{/*		<div className="row pb-5">*/}
			{/*			<div className="col-md-4 my-5 position-relative" style={{zIndex:1}}>*/}
			{/*				<div className='my-lg-4 challenge_block__text'>Только для первокурсников</div>*/}
			{/*				<div className='pb-lg-4'><h2>Выиграй ноутбук<br/> для учебы</h2></div>*/}
			{/*				<div className='foldtr'>и отдыха</div>*/}
			{/*			</div>*/}
			{/*			<div className="col-md-8 my-2 print_block position-absolute">*/}
			{/*				/!*<div className='pain d-none d-md-block' data-aos="fade-left" data-aos-duration="2500"><img src={Paint} alt="paint"/></div>*!/*/}
			{/*				/!*<div className='warrior d-none d-md-block'><img src={Warrior} alt="Warrior"/></div>*!/*/}
			{/*				<div className='book1'><img src={book1} alt="book1"/></div>*/}
			{/*				<div className='book2'><img src={book2} alt="book2"/></div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div>*/}

			{/*	</div>*/}
			{/*</div>*/}
			{/*<div className="container challenge mt-5">*/}
			{/*	<div className="row pb-lg-5">*/}
			{/*		<div className="col-lg-4 my-2 my-4">*/}
			{/*			<div className="ch_num">1</div>*/}
			{/*			<div className="mt-3">*/}
			{/*				Подпишись на наши соцсети*/}
			{/*				<div className='d-inline-block'>*/}
			{/*					<SocialPanel clsBlock={['d-flex', 'mt-1']} clsLink={['nav-link', 'active', 'p-2', 'soc_panel-event']}/>*/}
			{/*				</div>*/}

			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="col-lg-2 my-2 my-4">*/}
			{/*			<div className="ch_num">2</div>*/}
			{/*			<div className="mt-3 pt-3">Заполни <a href="https://forms.gle/yiJzK2UuMHRX9b8H8">анкету</a></div>*/}
			{/*		</div>*/}
			{/*		/!*<div className="col-lg-4 my-2 mt-4 mb-5">*!/*/}
			{/*		/!*	<div className="ch_num">3</div>*!/*/}
			{/*		/!*	<div className="mt-3 pt-3">Приходи на праздник 4 сентября</div>*!/*/}
			{/*		/!*</div>*!/*/}
			{/*	</div>*/}

			{/*</div>*/}

			<div className="guide_block my-5">
				<h2 className="text-center pb-4">Справочник первокурсника</h2>
				<GuidePanel/>
			</div>

		</MainLayout>
	)
}
